
import { Component, Vue } from 'vue-property-decorator'
import { CarBaseInfo } from '@/types/carManage'

@Component({
  name: 'DetailBase'
})
export default class extends Vue {
  private info: CarBaseInfo={
    // 基础信息
    projectId: '',
    vehicleType: '',
    vehicleName: '',
    brand: '',
    model: '',
    engineNumber: '',
    driver: '',
    driverMobile: '',
    driverLicenseNum: '',
    insuranceStartTime: '',
    insuranceEndTime: ''
  }

  created () {
    const id = this.$route.params.id
    id && this.getDetail(id)
  }

  getDetail (id: string) {
    this.$axios.get(this.$apis.carManage.selectYhVehicleByVehicleId, { vehicleId: id }).then(res => {
      if (res) {
        this.info = res
      }
    })
  }
}
